











































.sidebar-privacy {
  li {
    @apply py-1;
  }
}
