












































.sidebar-about-data {
  li {
    @apply py-1;
  }
}

.references {
  li {
    @apply py-1;
    word-break: break-word;
  }
}
