






















































blockquote {
  margin: 1rem 0rem 1rem 1rem;
}
.sidebar-about {
  .about-logo {
    max-width: 300px;
    margin: auto;
    max-height: 70px;
    min-width: 0;
    object-fit: scale-down;
  }
  .crc {
    max-width: 180px;
  }
  .logo-small {
    min-width: 0;
    object-fit: scale-down;
  }
}

article > img {
  margin: unset;
}
