







































.sidebar-feedback button.go,
.sidebar-feedback a.go {
  display: inline-block;
  @apply m-4 font-sans bg-green text-white font-bold py-4 px-4 no-underline;
}

.sidebar-feedback button.go:hover {
  @apply bg-green-dark;
}
